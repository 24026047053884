<template>
  <v-card
    flat
    class="pa-3 mt-2">
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="download()"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="sync()"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Синхронизировать</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="6"
          >
            <date-range v-model="filters.dateRange"></date-range>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      class="text-no-wrap"
    >

      <template #[`item.branch`]="{item}">
        {{ item.branch }}
      </template>

      <template #[`item.totalCount`]="{item}">
        {{ item.totalCount }}
      </template>

      <template #[`item.totalSum`]="{item}">
        {{ item.totalSum }}
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'digital-currency-by-branch', params: { branchId: item.branchId, start: filters.dateRange.start, end: filters.dateRange.end }}"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Детали</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import DateRange from '@/components/date-range'

export default {
  name: 'OrderDigitalCurrency',
  components: { DateRange },
  data: () => ({
    icons: {
      mdiEyeOutline,
      mdiExportVariant,
    },
  }),
  computed: {
    ...mapFormHandlers('digitalCurrency', [
      'filters.dateRange',
    ]),
    ...mapState('digitalCurrency', {
      data: 'list',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Филиал', value: 'branch' },
        { text: 'Количество заказов', value: 'totalCount' },
        { text: 'Сумма', value: 'totalSum' },
        {
          text: 'Детали',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    this.filters.dateRange.start = new Date().toISOString().substring(0, 10)
    this.filters.dateRange.end = this.filters.dateRange.start
    this.fetchData()
  },
  methods: {
    ...mapActions('digitalCurrency', {
      fetchData: 'fetchList',
      download: 'download',
      sync: 'sync',
    }),
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
