var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{staticClass:"multi-col-validation mt-6"},[_c('v-row',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.download()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.sync()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Синхронизировать")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-range',{model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.branch)+" ")]}},{key:"item.totalCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalCount)+" ")]}},{key:"item.totalSum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalSum)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'digital-currency-by-branch', params: { branchId: item.branchId, start: _vm.filters.dateRange.start, end: _vm.filters.dateRange.end }}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Детали")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }